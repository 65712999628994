.backwardDelivery {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;

    p {
        font-size: 1rem;
        font-weight: 400;
        line-height: 16px;
        color: #888;
        white-space: nowrap;
    }

    input {
        display: none;
    }

    span {
        position: relative;
        width: 16px;
        height: 16px;
        border-radius: 4px;
        background-color: #DDDFE3;
        pointer-events: none;
        transition: all .15s;
    }
    input:checked + span {
        background-color: #573971;

        &:after {
            position: absolute;
            content: '';
            left: 50%;
            top: 45%;
            transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
            width: 10px;
            height: 6px;
            border-bottom: 2px solid #fff;
            border-left: 2px solid #fff;
        }
    }
}