.orderActions {
  margin-bottom: 30px;

  > .row {
    display: flex;
    justify-content: space-between;

    &:first-child {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 15px;
    }

    a, span {
      display: block;
      font-size: 11px;
      line-height: 16px;
      color: #201827;
    }

    button, a {
      display: flex;
      align-items: center;
    }

  }

  .copyButton {
    span {
      padding-left: 7px;
    }

    img {
      width: 17px;
      height: 19px;
    }
  }

  .checkLink, .orderLink {
    position: relative;
    padding-left: 25px;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      width: 18px;
      height: 18px;
      transform: translate(0, -50%);
      background: url('../../../assets/icons/paperclip.svg') no-repeat left top;
    }
  }

  .checkLink.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .prodLink {
    position: relative;
    padding-left: 30px;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      width: 22px;
      height: 17px;
      transform: translate(0, -50%);
      background: url('../../../assets/icons/sew.svg') no-repeat left top;
    }
  }
}