.auth {
  background-color: #30223BE0;
  width: 100vw;
  height: 100vh;
  max-width: 768px;
  padding: 0 16px;

  &__logo {
    text-align: center;
    padding: 20px 0;
  }
}

.action {
  margin-top: 64px;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: 45px;
    background-color: #D9D1E0;
    border-radius: 4px;
    width: 100%;

    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }
}

