.orderStatus {
  width: 100vw;
  min-height: 100vh;
  max-width: 768px;
  padding: 10px 16px;
  color: #201827;

  h2 {
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #201827;
    text-align: center;
  }

  h3 {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 10px;
  }

  .statusBar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    margin-bottom: 10px;
    border-radius: 4px;
    background: #F5E97B;

    span {
      font-size: 12px;
      line-height: 12px;
    }
  }

  .priceBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    border-radius: 4px;
    background: #F1EEF3;
    margin-bottom: 30px;

    span {
      font-size: 14px;
      line-height: 24px;

      &.totalPrice {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }

  .bills {
    margin-bottom: 30px;
  }

  .newBillBtn {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #573971;
    border-radius: 4px;

    span {
      color: #fff;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 20px;
    }
  }
}