.totalPrice {
  margin-bottom: 10px;

  .priceText {
    font-size: 14px;
    line-height: 24px;
    color: #201827;
  }

  .price {
    line-height: 24px;
  }

  .priceInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .oldPriceValue {
    font-size: 14px;
    color: #726A79;
    text-align: right;
    text-decoration: line-through;
  }

  .totalPriceValue {
    font-size: 24px;
    font-weight: 700;
    color: #201827;
  }
}