.cancelOrder {
  padding-top: 20px;
  text-align: center;

  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 20px;
  }
}