.userMenu {
  height: 67px;
  position: fixed;
  left: 50%;
  bottom: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  background-color: #30223C;
  max-width: 768px;
  padding: 7px 16px;
  transform: translateX(-50%);
  z-index: 4;

  &__link, &__linkActive {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 5px 8px;
    transition: all .15s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    img {
      width: 23px;
      height: 100%;
    }

    span {
      margin-top: 3px;
      display: block;
      color: #fff;
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__linkActive {
    background-color: #fff;

    span {
      color: #201827;
    }
  }

}

.back {
  left: 50%;
  position: fixed;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 100vw;
  max-width: 768px;
  z-index: 3;
  background-color: #D9D1E0;
}

.hiddenLeftLink {
  display: flex;
  justify-content: center;
  align-items: center;
  left: -67px;
  bottom: 0;
  width: 67px;
  height: 67px;
  background: #D9D1E0;
  z-index: 3;
}

.rotated {
  width: 26px;
  transform: rotate(90deg);
  transition: all 300ms linear;
}

.swipeMenu {
  left: 0;
  z-index: 4;
  transition: all 20ms linear;
}

.hiddenRightLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: -67px;
  bottom: 0;
  width: 67px;
  height: 67px;
  padding: 12px 0;
  background: #D9D1E0;
  z-index:3;

  img {
    display: block;
  }

  span {
    margin-top: 3px;
    display: block;
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
  }
}

.logoutLink {
  background: #F37D76;
}

.returnLink {
  background: #573971;
}