.fabrics {
  &__search {
    width: 100%;
    margin: 10px 0 10px;
    position: relative;

    img {
      position: absolute;
      right: 11px;
      top: 50%;
      transform: translateY(-50%);
    }

    input {
      width: 100%;
      height: 40px;
    }
  }
}