.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  border-radius: 4px;
  background: #573971;

  span {
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 600;
  }

  &.inPopup {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
  }
}