.inputBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 37px;
  background: #F1EEF3;
  border-radius: 4px;
  padding: 6px 6px 6px 10px;
  margin-bottom: 10px;

  input {
    width: 239px;
    font-size: 1rem;
    line-height: 16px;
    font-weight: 500;
    color: #3d4658;
    background: #F1EEF3;

    &::placeholder {
      color: #3d4658;
      font-size: 10px;
    }
  }

  &__button {
    display: inline-flex;
    width: 56px;
    height: 25px;
    padding: 0;
    border: none;
    justify-content: center;
    align-items: center;
    background: #573971;
    cursor: pointer;
    border-radius: 4px;

    span {
      font-size: 10px;
      line-height: 16px;
      font-weight: 500;
      color: #F1EEF3;
    }
  }
}