.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background: #D9D1E0;
  margin-bottom: 10px;

  &.confirmBtn {
    background: #573971;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #201827;
  }

  &.confirmBtn {
    span {
      color: #FFFFFF;
    }
  }


}