.textBlock {
  display: block;
  width: calc(100% + 32px);
  height: 264px;
  margin: 0 -15px 10px -15px;
  padding: 20px 15px 30px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 22px;
  color: #5C5C5C;
  resize: none;
}