.payment {
  
  .title {
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    margin-bottom: 10px;
  }
}

.paymentBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 37px;
  padding: 0;
  border: none;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;

  img {
    height: 20px;
  }
}