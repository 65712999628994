.main {
  background-color: #D9D1E0;
  width: 100vw;
  min-height: 100vh;
  max-width: 768px;
  padding: 10px 16px;
}

.orderWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 18px;
}

.mainPagination {
  padding-top: 5px;
}