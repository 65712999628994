.filters {
    display: flex;
    column-gap: 8px;
    margin-bottom: 20px;

    .filter {
        width: 100%;

        &__label {
            height: 100%;
            padding: 4px 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #fff;
            transition: all 0.15s;
            border-radius: 3px;

            &:has(input:checked) {
                background-color: #573971;

                span {
                    color: white;
                }
            }

            input {
                display: none;
            }

            span {
                color: #201827;
                font-size: 10px;
                font-weight: 400;
                display: -webkit-box;
                line-clamp: 1;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}