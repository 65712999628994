.fabricChooser {
  display: flex;
  column-gap: 8px;

  &__else {
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-radius: 4px;
    background-color: #F1EEF3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.choose {
  width: 100%;

  input {
    display: none;
  }

  label {
    height: 100%;
    padding: 4px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
    transition: all .15s;
    border-radius: 3px;
  }

  :has(input:checked) {
    background-color: #573971;

    span {
      color: #fff;
    }
  }

  span {
    color: #201827;
    font-size: 10px;
    font-weight: 400;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

