.authForm {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-bottom: 90px;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &__field {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 5px;
      padding-left: 12px;
      color: #888;
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
    }

    input {
      font-size: 1rem;
    }
  }
}