.components {
  &__search {
    width: 100%;
    margin: 10px 0 10px;
    position: relative;

    img {
      position: absolute;
      right: 11px;
      top: 50%;
      transform: translateY(-50%);
    }

    input {
      width: 100%;
      height: 40px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: calc(100vh - 260px);
    overflow: auto;
    margin-bottom: 20px;

    .component {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__info {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 62px;
        height: 62px;
        background: #FFFFFF;
        border: 2px solid transparent;
        border-radius: 4px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        font-size: 10px;
        line-height: 16px;
        color: #201827;

        &__name {
          font-weight: 500;
        }
        &__price {
          font-weight: 700;
        }
      }

      &__btn {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          width: 31px;
          height: 31px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #573971;
          border-radius: 50%;

        }

        span {
          font-size: 10px;
          color: #201827;
          line-height: 16px;
        }
      }
    }
  }
}

.fabricChooser {
  display: flex;
  column-gap: 8px;
  margin-bottom: 19px;

  &__else {
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-radius: 4px;
    background-color: #F1EEF3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.choose {
  width: 100%;

  input {
    display: none;
  }

  label {
    height: 100%;
    padding: 4px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
    transition: all .15s;
    border-radius: 3px;
  }

  :has(input:checked) {
    background-color: #573971;

    span {
      color: #fff;
    }
  }

  span {
    color: #201827;
    font-size: 10px;
    font-weight: 400;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.chooserPopup {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.customCheckbox {
  display: inline-flex;
  align-items: center;
  column-gap: 15px;
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  span {
    width: 21px;
    height: 21px;
    border-radius: 4px;
    background-color: #fff;
    pointer-events: none;
    transition: all .15s;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: #535353;
    pointer-events: none;
  }
}
.customCheckbox:has(input:checked) {
  span {
    background-color: #573971;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      left: 50%;
      top: 45%;
      transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
      width: 10px;
      height: 6px;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
    }
  }
}