.btn {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 16px;
  background: #D9D1E0;
  border-radius: 4px;

  &__innerWrap {
    display: flex;
    align-items: center;
    column-gap: 2px;
  }

  &__plus {
    display: block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #573971;
  }
}