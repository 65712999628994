.preloaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.preloader{position:fixed;top:50%;left:50%;transform:translate(-50%, -50%);
  /*change these sizes to fit into your project*/
  width:100px;
  height:100px;
}
.preloader hr{border:0;margin:0;width:40%;height:40%;position:absolute;border-radius:50%;animation:spin 2s ease infinite}

.preloader :first-child{background:#30223C;animation-delay:-1.5s}
.preloader :nth-child(2){background:#62495c;animation-delay:-1s}
.preloader :nth-child(3){background:#f0b5b7;animation-delay:-0.5s}
.preloader :last-child{background:#ffffff}

@keyframes spin{
  0%,100%{transform:translate(0)}
  25%{transform:translate(160%)}
  50%{transform:translate(160%, 160%)}
  75%{transform:translate(0, 160%)}
}