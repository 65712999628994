.payChanger {
   /* padding: 3px 3px 3px 12px;*/
    /*display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 12px;
    row-gap: 8px;*/
    font-size: 0.75rem;
    color: #888;
    /*background-color: white;
    border-radius: 4px;*/

    .items {
        display: flex;
        column-gap: 5px;

        .item {
            padding: 2px 6px;
            line-height: 1.5rem;
            color: #30223B;
            background-color: #D9D1E0;
            border-radius: 4px;

            &:has(input:checked) {
                background-color: #30223B;
                color: white;
            }

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            input {
                display: none;
            }
        }
    }
}