.size {
  background: #F1EEF3;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;

  &__row {
    display: flex;
    /*column-gap: 20px;*/
    justify-content: space-between;
    margin-bottom: 5px;
  }

  &__kitRow {
    display: flex;
    column-gap: 20px;
    overflow: auto;
  }

  .item {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;

    &__panel {
      display: flex;
      width: 57px;
      height: 42px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: #ffffff;

      span {
        font-size: 16px;
        line-height: 31px;
        font-weight: 500;
      }
    }

    &__kitPanel {
      span {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }
    }

    &__fullName {
      text-align: center;
    }

    &.activeStandard {
      .item__panel {
        background: #573971;

        span {
          color: #ffffff;
        }
      }
    }

    &.activeNotStandard {
      .item__panel {
        background: #F37D76;

        span {
          color: #ffffff;
        }
      }
    }

    p {
      font-size: 9px;
      line-height: 16px;
      font-weight: 500;
      color: #201827;
    }
  }

  .kitItem {
    max-width: 80px;
    min-width: 76px;
  }

  &__descWrap {
    display: flex;
    column-gap: 10px;
    align-items: center;
  }
}

.desc {
  display: flex;
  align-items: center;
  column-gap: 5px;

  &__color {
    width: 11px;
    height: 11px;
    background: #573971;
    border-radius: 4px;
  }

  p {
    font-size: 7px;
    line-height: 16px;
    font-weight: 300;
  }

  &__notStandard {
    background: #F37D76;
  }
}

.sizeInputWrap {
  display: flex;

  input {
    &::placeholder {
      color: #30223B
    }
  }

  input:first-of-type {
    text-align: right;
  }

  input:nth-of-type(2) {
    text-align: left;
  }

  span {
    display: block;
    padding: 0 5px;
    width: auto;
    font-size: 48px;
    line-height: 100px;
    font-weight: 600;
    color: #30223B;
  }

  .focus {
    outline: 1px solid #573971;
  }
}