.storage {
  background-color: #D9D1E0;
  width: 100vw;
  min-height: 100vh;
  max-width: 768px;
  padding: 10px 16px;

  &__search {
    width: 100%;
    margin: 10px 0 10px;
    position: relative;

    img {
      position: absolute;
      right: 11px;
      top: 50%;
      transform: translateY(-50%);
    }

    input {
      width: 100%;
      height: 40px;
    }
  }

  .pagination {
    padding-top: 5px;
  }
}