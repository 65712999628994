.fabric {
  padding-top: 12px;

  &__row {
    display: flex;
    column-gap: 20px;
    overflow: auto;

    &:has(.fabric__itemWrap.fabric__active) {
      .fabric__itemWrap {
        > img {
          opacity: 0.7;
        }
      }
    }
  }

  &__itemWrap {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;

    p {
      font-size: 10px;
      line-height: 16px;
      color: #201827;
      font-weight: 500;
      text-align: center;
    }
  }

  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;
    background: #FFFFFF;
    border-radius: 4px;
  }

  &__itemImg {
    display: block;
    width: 62px;
    height: 62px;
    object-fit: cover;
    border-radius: 4px;
  }

  &__active {

    > img {
      opacity: 1 !important;
      border: 2px solid #573971;
    }
  }

  &__active {
    > p {
      font-weight: 700;
    }
  }
}