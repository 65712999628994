.mainTabs {
  margin-top: 20px;
}

.tabs {
  margin-bottom: 2px;
  display: flex;
  justify-content: space-around;
}

.tab,
.tabActive {
  padding: 10px;
  position: relative;
}

.tab span,
.tabActive span {
  color: #201827;
  font-size: 12px;
  font-weight: 600;
}

.tabActive span {
  color: #573971;
  font-size: 12px;
  font-weight: 600;
}

.tabActive:after {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #573971;

}

.line {
  height: 2px;
  width: 100%;
  background-color: #fff;
}