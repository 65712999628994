.delivery {
  padding: 20px 48px 0;
  text-align: center;

  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 20px;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background: #D9D1E0;
  margin-bottom: 10px;

  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #201827;
  }
}

.confirmBtn {
  background: #573971;

  span {
    color: #FFFFFF;
  }
}