.detailsPayment {
  background-color: #D9D1E0;
  min-height: 100vh;
  padding: 0 16px 100px;

  &__logo {
    text-align: center;
    padding: 20px 0;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }
}

.content {
  padding: 10px 15px 20px;
  background-color: #F1EEF3;

  &__title {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    color: #201827;
    text-align: center;
    margin-bottom: 10px;
  }

  &__text {
    color: #888;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 10px;
  }

  &__error {
    color: #DC6666;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__label {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    color: #888;
  }

  &__box {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  &__field {
    background-color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}