.btnGroup {
  display: flex;
  column-gap: 10px;
  margin-bottom: 20px;
}

.checkBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 37px;
  padding: 0;
  border: none;
  background: #D9D1E0;
  border-radius: 6px;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.fileBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 37px;
  flex: 0 0 auto;
  padding: 0;
  border: none;
  background: #D9D1E0;
  border-radius: 6px;
  cursor: pointer;

  input {
    display: none;
  }
}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  span {
    padding-left: 26px;
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: #201827;
    
    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background: #6CD784 no-repeat;
      border-radius: 4px;
    }
  }
}

.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  appearance: none;
  -webkit-appearance: none;
  z-index: 2;
  cursor: pointer;

  &:checked + span:after {
    position: absolute;
    content: '';
    left: 3px;
    top: 4px;
    width: 10px;
    height: 6px;
    border-left: 3px solid #ffffff;
    border-bottom: 3px solid #ffffff;
    border-radius: 1px;
    transform: rotate(-45deg);
  }
}

.sendBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 37px;
  padding: 0;
  border: none;
  background: #573971;
  border-radius: 6px;
  cursor: pointer;

  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #FFFFFF;
  }
}

.approvedText {
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: #201827;
  margin-bottom: 20px;
}
.approvedText.declined {
  color: red;
}