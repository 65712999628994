.productItem {
  background: #F1EEF3;
  border-radius: 4px;
  padding: 15px 15px 0;
  border: 1px solid transparent;
  margin-bottom: 10px;

  input {
    display: block;
    width: 100%;
    height: 36px;
    padding: 10px 12px;
    border-radius: 4px;
    border: none;
    background: #FFFFFF;
    font-size: 12px;
    line-height: 16px;
    color: #201827;
  }

  label {
    display: block;
    padding-left: 12px;
    padding-bottom: 2px;
    font-size: 10px;
    line-height: 16px;
    color: #888888;
  }

  textarea {
    display: block;
    width: 100%;
    height: 36px;
    padding: 10px 12px;
    border-radius: 4px;
    resize: none;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 16px;
    color: #201827;
  }

  .notFound {
    padding-bottom: 15px;
  }
}

.imgPlaceholder {
  position: relative;
  width: 100%;
  height: 220px;
  border: none;
  border-radius: 4px;
  background: #30223B;
  margin-bottom: 20px;

  .mainImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
  }

  .placeholderImg {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: auto;
    height: auto;
    object-fit: fill;
  }

  .copyButton {
    position: absolute;
    right: 15px;
    bottom: 15px;
    padding: 5px;
    margin-left: -5px;
    margin-bottom: -5px;
    background-color: rgb(158 158 158 / 0.8);
    border-radius: 4px;
    z-index: 2;
  }
}

.formGroup {
  flex-grow: 1;
}

.productInfo {
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .title {
    display: block;
    padding-left: 12px;
    padding-bottom: 2px;
    font-size: 10px;
    line-height: 16px;
    color: #888888;
  }

  .content {
    padding: 10px 12px;
    background-color: white;
    border-radius: 4px;
  }

  .value {
    font-size: 12px;
    line-height: 16px;
    color: #201827;
  }
}

.inputRow {
  display: flex;
  column-gap: 16px;
  margin-bottom: 10px;

  &.withSlider {
    padding-top: 110px;
  }
}

.inputWrap {
  position: relative;

  img {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.warning {
  font-size: 10px;
  line-height: 16px;
  color: #DC6666;
  padding-top: 2px;
}

.changeRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;

  span {
    display: block;
    padding-left: 5px;
    font-size: 12px;
    line-height: 20px;
    color: #201827;
    font-weight: 600;
  }
}

.changeBtn {
  display: flex;
  align-items: center;
}

.panel {
  display: flex;
  padding: 7px 0 7px;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 0 0 4px 4px;

  button {
    img {
      display: block;

      &.arrUp {
        transform: rotate(180deg);
      }
    }
  }
}

.closedWrap {
  display: flex;
  column-gap: 20px;
  padding-bottom: 15px;

  img {
    display: block;
    width: 77px;
    height: 54px;
    border-radius: 4px;
    object-fit: cover;
  }

  &__title {
    font-size: 12px;
    line-height: 22px;
    color: #888888;
    margin-bottom: 3px;

    & + p {
      font-size: 16px;
      line-height: 22px;
      color: #535353;
    }
  }
}

