.notFound {
    min-height: 100svh;
    display: flex;
    align-items: center;
    padding-top: 100px;
    flex-direction: column;
    background-color: #D9D1E0;

    .message {
        flex-grow: 1;
        font-size: 20px;
        font-weight: 600;
        line-height: 36px;
        text-align: center;
        margin-bottom: 20px;
    }

    .link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        background: #573971;

        span {
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
            font-weight: 600;
        }
    }
}