.userHeader {
  background-color: #EFE9F4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px 9px;
  border-radius: 4px;

  &__user {
    display: flex;
    align-items: center;
    column-gap: 10px;

    p {
      display: flex;
      flex-direction: column;
      row-gap: 6px;
    }
  }

  &__userPhoto {
    width: 39px;
    height: 39px;
    border-radius: 50%;
    border: 1px solid #573971;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 39px;
      height: 39px;
    }
  }
}

.name {
  color: #5C5C5C;
  font-size: 12px;
  font-weight: 400;
}

.role {
  color: #A0A0A0;
  font-size: 10px;
  font-weight: 400;
}