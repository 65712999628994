.CallbackForm {
  margin: 10px 0 20px;
  border-radius: 4px;
  background-color: #F1EEF3;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  input {
    height: 36px;
    border-radius: 4px;
    padding: 0 12px;
    color: #888888;
    font-size: 1rem;
    font-weight: 400;
    line-height: 16px;
    background-color: #FFFFFF;

    &::placeholder {
      opacity: 1;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
}

.buttonField {
  height: 36px;
  border-radius: 4px;
  padding: 0 12px;
  background-color: #FFFFFF;
  text-align: start;

  span {
    color: #888888;
    font-size: 1rem;
    font-weight: 400;
    line-height: 16px;
  }
}

.action {
  background-color: #573971;
  border-radius: 4px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
  }
}

.action:disabled {
  opacity: 0.3;
}