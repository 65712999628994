.done {
  padding-top: 100px;
  text-align: center;
  color: #201827;

  &__check {
    display: block;
    width: 58px;
    height: 58px;
    margin: 0 auto 16px;
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 20px;
  }

  &__back {
    display: block;
    font-size: 10px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: underline;
    margin: 0 auto 10px;
    color: #201827;
  }
}