.callback {
  background-color: #D9D1E0;
  min-height: 100vh;
  padding: 0 16px 100px;

  &__logo {
    text-align: center;
    padding: 20px 0;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }

  &__text {
    color: #201827;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

.workTime {
  margin: 10px 0 15px;

  &__left, &__right {
    border-radius: 4px;
    height: 46px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;

    span {
      color: #535353;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }

    p {
      color: #201827;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  &__left {
    background-color: #fff;
    margin-bottom: 10px;
  }

  &__right {
    background-color: #F1EEF3;
  }
}

